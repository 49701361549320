export const features = [
  {
    id: 1,
    title: 'Hop! - Social',
    path: 'hop-social',
    description:
      'A social media application developed for iOS in order to chat with people at different nightlife locations around you.',
    about:
      'Hop! is a location-based social media application I am developing that is currently beta testing. The intent is to see social locations in the immediate area, and be able to make posts relevant to the locations as well as chat with other people at these locations. The application is currently only available on iOS 14.0 and higher. It was developed with Swift, SwiftUI, and Firebase. If you would like to participate in the beta testing, check out the link below!',
    images: ['/images/hop.jpg'],
    url: 'https://hopsocial.app/',
    cta: 'Try it out!',
    tags: [
      'Swift',
      'SwiftUI',
      'Firebase',
      'Yelp Fusion API',
      'UX',
      'Design',
      'App Development',
      'Git',
      'API'
    ]
  },
  {
    id: 0,
    title: 'Logic Gate Blocks',
    path: 'logic-gate-blocks',
    description:
      'Logic Gate Blocks offer a physical and accessible way to more easily interact with and understand the fundamental component of computer hardware known as logic gates.',
    about:
      'Logic Gate Blocks are an interactive, physical representation of logic gates. They are a series of modular blocks that can be connected to each other in a variety of ways in order to simulate the behavior of logic gates and gain a better understanding of their functionality. The final outcome of this project can be described as Legos, but for building and simulating combinational logic circuits. Logic gates are based on boolean algebra, and are how computers make decisions and interpret information in a binary language. They receive binary inputs and produce a singular binary output based on the functionality of the gate. The four combinational logic gates created for this project are known as NOT, AND, OR, and XOR. Each of these blocks has its own distinct behavior. An input block has a switch allowing it to be turned on and off. This block can be connected to the input of a gate block and allows the participant to interact with the circuit. The output block lights up depending on the state of its input. It can be connected at the end of a circuit or at any point during the circuit where the participant may want to know the state at that particular point. These blocks are connected to each other using 3.5mm audio jack cables and splitters. Together, the input, gate, and output blocks allow the participant to create and interact with combinational logic circuits.',
    images: ['/images/logic-gate-blocks.jpg', ''],
    tags: [
      'Fabrication',
      'UX',
      '3D Printing',
      'Electronics',
      'Interaction Design',
      'Prototyping',
      'Product Design'
    ],
    award: 'NYU Prototyping Fund Recipient'
  }
];
